/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MutateYamlFileInput = {|
  id?: ?string,
  data?: ?any,
  clientMutationId?: ?string,
|};
export type FileViewsYamlMutationVariables = {|
  input: MutateYamlFileInput
|};
export type FileViewsYamlMutationResponse = {|
  +updateYaml: ?{|
    +file: ?{|
      +id: string,
      +name: ?string,
      +path: ?string,
      +text: ?string,
      +yaml: ?any,
    |}
  |}
|};
export type FileViewsYamlMutation = {|
  variables: FileViewsYamlMutationVariables,
  response: FileViewsYamlMutationResponse,
|};
*/


/*
mutation FileViewsYamlMutation(
  $input: MutateYamlFileInput!
) {
  updateYaml(input: $input) {
    file {
      id
      name
      path
      text
      yaml
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MutateYamlFilePayload",
    "kind": "LinkedField",
    "name": "updateYaml",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "file",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "yaml",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FileViewsYamlMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FileViewsYamlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a4f398ea75df515d6bf53999cf37ed59",
    "id": null,
    "metadata": {},
    "name": "FileViewsYamlMutation",
    "operationKind": "mutation",
    "text": "mutation FileViewsYamlMutation(\n  $input: MutateYamlFileInput!\n) {\n  updateYaml(input: $input) {\n    file {\n      id\n      name\n      path\n      text\n      yaml\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7dae4f6ecadca2c3c0ff67cfe1069830';

module.exports = node;
