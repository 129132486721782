import React, {useEffect, useState} from "react";
import {useToggle, useInterval} from "react-use";
import styled from "styled-components";
import graphql from "babel-plugin-relay/macro";
import {fetchQuery} from 'relay-runtime';
import {Box, Image, RangeInput, Video} from "grommet";
import Ansi from "ansi-to-react";

import {inc, modernEnvironment} from "../data";
import {by, commonPrefix, strOrder, subPrefix} from "../lib/string-sort";
import {pathJoin} from "../lib/path-join";
import store from "../local-storage";
import LineChart from "./LineChart";
import {commitMutation} from "react-relay";
import MonacoEditor from "react-monaco-editor";
import {toGlobalId} from "../lib/relay-helpers";
import {RowContainer} from "../components/layouts";
import JSON5 from "json5";
import CompoundChart, {numOfFacets, numOfLines} from "./CompoundChart";
import IframeResizer from "iframe-resizer-react"
import {RefreshCw, X} from "react-feather";
import ReactMarkdown from "react-markdown";
import rehypeMathJax from "rehype-mathjax";
// import { Remark } from 'react-remark';
// import ReactMarkdownWithHtml from "react-markdown/with-html";
// import remarkParse from 'remark-parse';
// import remarkRehype from "remark-rehype";
// import rehypeStringify from "rehype-stringify";
import remarkGfm from 'remark-gfm';
import frontMatter from 'remark-frontmatter';
import remarkMath from 'remark-math';
import rehypeRaw from 'rehype-raw'
import remarkDirective from 'remark-directive';
import remarkDirectiveRehype from "remark-directive-rehype/dist";
// import reactMdx from 'remark-mdx';

const globQuery = graphql`
    query FileViewsQuery ($cwd: String!, $glob: String) {
        glob ( cwd: $cwd, query: $glob) {
            id name path
        }
    }
`;


function globFiles({cwd, glob}) {
    return fetchQuery(modernEnvironment, globQuery, {cwd, glob});
}

export function fetchTextFile(path) {
    let id = toGlobalId("File", path);
    return fetchQuery(modernEnvironment, graphql`
        query FileViewsTextFileQuery ($id: ID!) {
            node (id: $id) {
                id
                ... on File { text }
            }
        }`, {id})
}

export function fetchAllCharts(path) {
    let id = toGlobalId("Directory", path);
    return fetchQuery(modernEnvironment, graphql`
        query FileViewsChartsQuery ($id: ID!) {
            node (id: $id) {
                id
                ... on Directory  {
                    path
                    charts {
                        edges {
                            cursor
                            node {id dir name path text yaml}
                        }
                    }
                }
            }
        }`, {id})

}

export function fetchYamlFile(path) {
    let id = toGlobalId("File", path);
    return fetchQuery(modernEnvironment, graphql`
        query FileViewsYamlFileQuery ($id: ID!) {
            node (id: $id) {
                id
                ... on File {id dir name path text yaml}
            }
        }`, {id})
}

function updateText(path, text) {
    // returns a relay.Disposable
    let id = toGlobalId("File", path);
    return commitMutation(modernEnvironment, {
        mutation: graphql`
            mutation FileViewsTextMutation($input: MutateTextFileInput!) {
                updateText (input: $input) {
                    file { id name path text}
                }
            }
        `,
        variables: {
            input: {id, text, clientMutationId: inc()},
        },
        configs: []
    });
}

//note: use the same resolver as the writer
function updateYaml(path, data) {
    let id = toGlobalId("File", path);
    return commitMutation(modernEnvironment, {
        mutation: graphql`
            mutation FileViewsYamlMutation($input: MutateYamlFileInput!) {
                updateYaml (input: $input) {
                    file { id name path text yaml}
                }
            }
        `,
        variables: {
            input: {id, data, clientMutationId: inc()},
        },
        configs: []
    });
}

//todo: add width and height to the query
function YouTube({id, children}) {
    return (
        <IframeResizer
            src={`https://www.youtube.com/embed/${id}?modestbranding=1&rel=0`}
            log
            style={{width: "1px", minHeight: "240px", minWidth: "320px"}}
            allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; encrypted-media"
            allowFullScreen
            frameBorder="0"
            checkOrigin={false}
            title="YouTube Video"
        >
            {children}
        </IframeResizer>
    )
}

//todo: add width and height to the query
function PointCloud({label, path, node: {children}, ...props}) {
    const text = children.map(n => n.children[0].properties.href).join(',')
    const queries = children ? `urls=${text}` : `path=${path}`
    return (
        <IframeResizer
            alt={label}
            src={`https://dash.ml/demos/vqn-dash/pcd?${queries}`}
            style={{width: "100%", minHeight: "240px", minWidth: "320px"}}
            frameBorder="0"
            title="PointCloud"
            {...props}
        />
    )
}

const StyledButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2000;
`;

export function MarkdownEditor({path, content, onChange = true, placeholder = "", ..._props}) {
    const [text, setText] = useState(placeholder || "");
    if (onChange === true) onChange = (value) => {
        setText(value);
        updateText(path, value)
    };
    else if (!onChange) onChange = undefined;

    useInterval(() => {
        if (!!content) return setText(content);
        fetchTextFile(path).then(({node, errors}) => {
            if (node) {
                setText(node.text || "");
            } else {
                setText(placeholder);
            }
        });
    }, [500]);

    const [showEditor, setShowEditor] = useState(false);
    if (showEditor) {
        return <div style={{
            position: "relative", minHeight: "500px", height: "100%", bottom: "0", width: "100%",
            borderRadius: "14px 14px 0 0",
            flexGrow: 100,
        }}>
            <StyledButton onClick={() => setShowEditor(false)}>Show Preview</StyledButton>
            <MonacoEditor width="100%"
                          height="100%"
                          language="yaml"
                          theme="vs-github"
                          value={text}
                          options={{
                              wordWrap: "on",
                              selectOnLineNumbers: true,
                              folding: true,
                              automaticLayout: true
                          }}
                          onChange={onChange}
                          editorDidMount={() => null}
            />
        </div>
    } else {
        return <div style={{
            position: "relative", minHeight: "500px", bottom: "0", width: "100%",
        }}>
            <StyledButton onClick={() => setShowEditor(true)}>Edit This</StyledButton>
            <article style={{
                boxSizing: "border-box",
                padding: "30px 5%",
                width: "100%",
                height: "100%",
                background: "white",
                overflowWrap: "break-word",
                overflowY: "auto",
            }}><ReactMarkdown
                skipHtml={false}
                // transformImageUri={}
                // transformLinkUri={}
                // renderers={{link: ({node, ...props}) => <a {...props} />}}
                renderers={{
                    math: ({value}) => `math: ${value}`,
                    inlineMath: ({value}) => `inlineMath: ${value}`
                }}
                components={{
                    "youtube": YouTube,
                    "point-cloud": PointCloud,
                    "pcd": PointCloud
                }}
                // remarkPlugins={[remarkDirective, remarkDirectiveRehype]}
                remarkPlugins={[
                    frontMatter,
                    remarkGfm,
                    remarkMath,
                    remarkDirective,
                    remarkDirectiveRehype,
                    // Mdx kills iframes.
                    // reactMdx,
                ]}
                rehypePlugins={[rehypeRaw, rehypeMathJax,]}
            >{
                text
            }</ReactMarkdown></article>
        </div>
    }
}


const StyledText = styled.pre`
  overflow: auto
`;

export function TextView({path, ansi = false}) {
    //todo: add scroll bar
    const [text, setText] = useState("");
    useEffect(() => {
        let running = true;
        const abort = () => running = false;
        fetchTextFile(path).then(({node, errors}) => {
            if (running) {
                if (node) setText(node.text || "");
                else setText("");
            }
        });
        return abort;
    }, [path, setText]);
    return <StyledText>{ansi ? <Ansi>{text}</Ansi> : text}</StyledText>;
}

export function TextEditor({path, content = null, onChange = true, ..._props}) {
    //todo: add scroll bar
    const [text, setText] = useState("");
    if (onChange === true) onChange = (value) => {
        setText(value);
        updateText(path, value)
    };
    else if (!onChange) onChange = undefined;

    useEffect(() => {
        if (content !== null) return setText(content);
        fetchTextFile(path).then(({node, errors}) => {
            if (node) setText(node.text || "");
            else setText("");
        });
    }, [path, content, setText, ...Object.values(_props)]);
    return <MonacoEditor width="100%"
                         height="100%"
                         language="yaml"
                         theme="vs-github"
                         value={text}
                         options={{
                             selectOnLineNumbers: true,
                             folding: true,
                             automaticLayout: true
                         }}
                         onChange={onChange}
                         editorDidMount={() => null}/>
}

export function ImageView({width = "100%", height = "100%", src}) {
    //todo: add scroll bar
    return <Image src={src} style={{
        maxWidth: width, maxHeight: height,
        objectFit: "contain",
        width: width, height: height,
        imageRendering: "pixelated",
        borderRadius: 10
    }}/>;
}

export function VideoView({width = "100%", height = "100%", src}) {
    //todo: add scroll bar
    if (!src) return <span>still loading</span>;
    return <Video style={{
        maxWidth: width, maxHeight: height,
        objectFit: "contain",
        borderRadius: 10
    }}>
        <source key="video" src={src} type="video/mp4"/>
        {/*<track key="cc" label="English" kind="subtitles" srcLang="en" src="/assets/small-en.vtt" default/>*/}
    </Video>
}

export const StyledTitle = styled(RowContainer)`
  height: 18px;
  margin: 3px 0;
  box-sizing: border-box;
  height: 1.5em;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  > .title {
    flex: 0 0 auto;
    display: inline-block;
    border-radius: 10px;
    color: #555;
    font-weight: 500;

    &:hover {
      color: white;
      background: #23aaff;
    }

    padding: 0 1em;
    height: 1.5em;
    line-height: 1.5em;
    position: relative;

    .close {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 3px;
    }
  }

  > .spacer {
    flex: 1 1 auto;
  }

  > .input {
    border: solid 1px #23aaff;
    margin: -1px;
    color: #23aaff;
    font-weight: 700;
    background: white;
  }

  > .control {
    flex: 0 0 auto;
    display: inline-block;
    color: black;
    opacity: 0.6;
    margin: 0 3px;
    border-radius: 10px;
    width: 1.5em;
    height: 1.5em;
    padding: 0 0.7em;

    &:hover {
      color: white;
      background: gray;
    }

    position: relative;

    svg {
      stroke-width: 3;
      width: 12px;
      height: 12px;
      stroke-width: 3;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
`;

const MainContainer = styled.div`
  height: 200px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function InlineFile({type, prefix, glob, title, src, ...chart}) {
    const [files, setFiles] = useState([]);
    const [index, setIndex] = useState(-1);
    const [showConfig, toggleShowConfig] = useToggle();

    //does not allow multiple directories
    // if (typeof cwd === 'object') return null;

    useEffect(() => {
        let running = true;
        const abort = () => running = false;
        globFiles({cwd: prefix, glob}).then(({glob, errors}) => {
            if (running && glob)
                setFiles([...glob].sort(by(strOrder, "path")));
        });
        return abort;
    }, [prefix, glob, setFiles]);

    const selected = files[index >= 0 ? index : (files.length + index)];
    const pathPrefix = commonPrefix(files.map(({path}) => path));

    src = src || (selected
        ? pathJoin(store.value.profile.url, "files", selected.path.slice(1))
        : null);

    // if type === "file" type = fileTypes(src);
    let viewer; // only render if src is valid. Otherwise breaks the video component.
    if (type === "video" && src) viewer = <VideoView src={encodeURI(src)}/>;
    else viewer = <MainContainer><ImageView src={encodeURI(src)}/></MainContainer>;

    return <>
        <Box>
            <StyledTitle onClick={() => toggleShowConfig(!showConfig)}>
                <span className="spacer"/>
                <span className="title" title={selected && selected.path}
                      padding="2em">{selected ? selected.name : (title || "N/A")}</span>
                <span className="spacer"/>
            </StyledTitle>{viewer}
        </Box>
        {showConfig
            ? <div>
                <Box direction={"row"} gap={'none'} height={30}>
                    <RangeInput value={index}
                                min={-10} max={files.length - 1}
                                style={{
                                    margin: 0,
                                    width: 100,
                                    height: "30px",
                                    display: "inline-block"
                                }}
                                onChange={e => setIndex(parseInt(e.target.value))}/>
                    <input style={{
                        width: 40, height: "30px", margin: 0, border: 0, boxSizing: "border-box",
                        background: "transparent",
                        marginLeft: "5px",
                        textAlign: "center"
                    }}
                           type="number"
                           value={index}
                           onChange={e => setIndex(parseInt(e.target.value))}/>
                </Box>
                {/*make multiple col span wide*/}
                {selected
                    ? <>
                        <p>
                            <strong>glob</strong>: <span style={{display: "inline-block"}}>{glob}</span>
                        </p>
                        <p>
                            <strong>file</strong>:
                            <span style={{display: "inline-block", wordBreak: "break-word"}}>{
                                subPrefix(selected.path, pathPrefix)}</span>
                        </p>
                    </>
                    : null}
            </div>
            : null}
    </>
}

export function InlineChart({configPath = null, onRefresh = null, title, ...chart}) {
    //path is the link to the chart file
    const [showEditor, toggleEditor] = useToggle();

    if (!title) title = chart.yKey || chart.yKeys.join(', ');

    let numSpan = 1;
    if (chart.metricsGroups) numSpan = numOfFacets(chart);
    if (numOfLines(chart) > 3) numSpan *= 2;

    const style = {gridColumn: `span ${numSpan}`};

    const charts = (!!chart.metricsGroups) ? <CompoundChart {...chart}/> : <LineChart {...chart}/>

    return <>
        <Box style={style}>
            <StyledTitle>
                {!!onRefresh
                    ?
                    <span className="control" onClick={onRefresh}><RefreshCw color={"#afafaf"} height={12} width={12}/></span>
                    : null}
                <span className="spacer"/>
                <span className="title" onClick={toggleEditor} title={title || "N/A"}>{title || "N/A"}</span>
                <span className="spacer"/>
            </StyledTitle>
            <MainContainer>{charts}</MainContainer>
        </Box>
        {(showEditor && configPath)
            ? <Box style={{gridColumn: "span 2"}}>
                <StyledTitle>
                    <span className="spacer"/>
                    <span className="title" title={configPath}>{configPath}</span>
                    <span className="control" onClick={() => toggleEditor(false)}><X height={12} width={12}/></span>
                    <span className="spacer"/>
                </StyledTitle>
                <MainContainer>
                    <TextEditor path={configPath}/>
                </MainContainer>
            </Box>
            : null}
    </>
}

//id, dir, name, path, text, yaml: {}
export function AnyChart({type, ...chart}) {

    switch (type) {
        case "series":
            return <InlineChart key={JSON5.stringify(chart)} color="#23aaff" {...chart} />;
        case "file":
        case "video":
        case "mov":
        case "movie":
        case "img":
        case "image":
            // cwd={path} glob={chart.glob} src={chart.src}
            return <InlineFile type={type} {...chart}/>;
        default:
            return null;
    }
}