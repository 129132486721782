/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LineChartsQueryVariables = {|
  prefix?: ?string,
  xKey?: ?string,
  xAlign?: ?string,
  yKey?: ?string,
  yKeys?: ?$ReadOnlyArray<?string>,
  k?: ?number,
  metricsFiles: $ReadOnlyArray<?string>,
|};
export type LineChartsQueryResponse = {|
  +series: ?{|
    +id: string,
    +xKey: ?string,
    +yKey: ?string,
    +xData: ?any,
    +yMean: ?any,
    +y25pc: ?any,
    +y75pc: ?any,
  |}
|};
export type LineChartsQuery = {|
  variables: LineChartsQueryVariables,
  response: LineChartsQueryResponse,
|};
*/


/*
query LineChartsQuery(
  $prefix: String
  $xKey: String
  $xAlign: String
  $yKey: String
  $yKeys: [String]
  $k: Int
  $metricsFiles: [String]!
) {
  series(metricsFiles: $metricsFiles, prefix: $prefix, k: $k, xKey: $xKey, yKey: $yKey, yKeys: $yKeys, xAlign: $xAlign) {
    id
    xKey
    yKey
    xData
    yMean
    y25pc
    y75pc
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "k"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metricsFiles"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prefix"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "xAlign"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "xKey"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yKey"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yKeys"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "k",
        "variableName": "k"
      },
      {
        "kind": "Variable",
        "name": "metricsFiles",
        "variableName": "metricsFiles"
      },
      {
        "kind": "Variable",
        "name": "prefix",
        "variableName": "prefix"
      },
      {
        "kind": "Variable",
        "name": "xAlign",
        "variableName": "xAlign"
      },
      {
        "kind": "Variable",
        "name": "xKey",
        "variableName": "xKey"
      },
      {
        "kind": "Variable",
        "name": "yKey",
        "variableName": "yKey"
      },
      {
        "kind": "Variable",
        "name": "yKeys",
        "variableName": "yKeys"
      }
    ],
    "concreteType": "Series",
    "kind": "LinkedField",
    "name": "series",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "yKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xData",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "yMean",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "y25pc",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "y75pc",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LineChartsQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LineChartsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "ff897c07028d807b2aab384d0c396ea6",
    "id": null,
    "metadata": {},
    "name": "LineChartsQuery",
    "operationKind": "query",
    "text": "query LineChartsQuery(\n  $prefix: String\n  $xKey: String\n  $xAlign: String\n  $yKey: String\n  $yKeys: [String]\n  $k: Int\n  $metricsFiles: [String]!\n) {\n  series(metricsFiles: $metricsFiles, prefix: $prefix, k: $k, xKey: $xKey, yKey: $yKey, yKeys: $yKeys, xAlign: $xAlign) {\n    id\n    xKey\n    yKey\n    xData\n    yMean\n    y25pc\n    y75pc\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b70b4149ddbe29e232666e4d48c8961f';

module.exports = node;
