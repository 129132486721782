/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FileViewsTextFileQueryVariables = {|
  id: string
|};
export type FileViewsTextFileQueryResponse = {|
  +node: ?{|
    +id: string,
    +text?: ?string,
  |}
|};
export type FileViewsTextFileQuery = {|
  variables: FileViewsTextFileQueryVariables,
  response: FileViewsTextFileQueryResponse,
|};
*/


/*
query FileViewsTextFileQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    id
    ... on File {
      text
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FileViewsTextFileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FileViewsTextFileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc969afc03c88a0328d28637a5eec0da",
    "id": null,
    "metadata": {},
    "name": "FileViewsTextFileQuery",
    "operationKind": "query",
    "text": "query FileViewsTextFileQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on File {\n      text\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd84d85675bae8bbff353655173e14fe1';

module.exports = node;
