/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InlineExperimentViewDirectoryQueryVariables = {|
  id: string
|};
export type InlineExperimentViewDirectoryQueryResponse = {|
  +directory: ?{|
    +id: string,
    +name: ?string,
    +path: ?string,
    +directories: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +name: ?string,
          +path: ?string,
        |}
      |}>
    |},
    +files: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +name: ?string,
          +path: ?string,
        |}
      |}>
    |},
  |}
|};
export type InlineExperimentViewDirectoryQuery = {|
  variables: InlineExperimentViewDirectoryQueryVariables,
  response: InlineExperimentViewDirectoryQueryResponse,
|};
*/


/*
query InlineExperimentViewDirectoryQuery(
  $id: ID!
) {
  directory(id: $id) {
    id
    name
    path
    directories(first: 100) {
      edges {
        node {
          name
          path
          id
        }
      }
    }
    files(first: 100) {
      edges {
        node {
          name
          path
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v6 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v7 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InlineExperimentViewDirectoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Directory",
        "kind": "LinkedField",
        "name": "directory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "DirectoryConnection",
            "kind": "LinkedField",
            "name": "directories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Directory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "directories(first:100)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "FileConnection",
            "kind": "LinkedField",
            "name": "files",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "files(first:100)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InlineExperimentViewDirectoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Directory",
        "kind": "LinkedField",
        "name": "directory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "DirectoryConnection",
            "kind": "LinkedField",
            "name": "directories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Directory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "directories(first:100)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "FileConnection",
            "kind": "LinkedField",
            "name": "files",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "files(first:100)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52b1342e52caaed5c554a1cc4dd79ad6",
    "id": null,
    "metadata": {},
    "name": "InlineExperimentViewDirectoryQuery",
    "operationKind": "query",
    "text": "query InlineExperimentViewDirectoryQuery(\n  $id: ID!\n) {\n  directory(id: $id) {\n    id\n    name\n    path\n    directories(first: 100) {\n      edges {\n        node {\n          name\n          path\n          id\n        }\n      }\n    }\n    files(first: 100) {\n      edges {\n        node {\n          name\n          path\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c67657d8044b23e4d74301440fcc59b7';

module.exports = node;
