/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InlineExperimentViewQueryVariables = {|
  id: string
|};
export type InlineExperimentViewQueryResponse = {|
  +experiment: ?{|
    +id: string,
    +name: ?string,
    +path: ?string,
    +parameters: ?{|
      +name: ?string,
      +path: ?string,
      +flat: ?any,
    |},
    +metrics: ?{|
      +name: ?string,
      +path: ?string,
      +keys: ?$ReadOnlyArray<?string>,
    |},
    +directories: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +name: ?string,
          +path: ?string,
        |}
      |}>
    |},
    +files: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +name: ?string,
          +path: ?string,
        |}
      |}>
    |},
  |}
|};
export type InlineExperimentViewQuery = {|
  variables: InlineExperimentViewQueryVariables,
  response: InlineExperimentViewQueryResponse,
|};
*/


/*
query InlineExperimentViewQuery(
  $id: ID!
) {
  experiment(id: $id) {
    id
    name
    path
    parameters {
      name
      path
      flat
      id
    }
    metrics {
      name
      path
      keys
      id
    }
    directories(first: 100) {
      edges {
        node {
          name
          path
          id
        }
      }
    }
    files(first: 100) {
      edges {
        node {
          name
          path
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flat",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keys",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v8 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v9 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InlineExperimentViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Experiment",
        "kind": "LinkedField",
        "name": "experiment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Parameters",
            "kind": "LinkedField",
            "name": "parameters",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Metrics",
            "kind": "LinkedField",
            "name": "metrics",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "DirectoryConnection",
            "kind": "LinkedField",
            "name": "directories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Directory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "directories(first:100)"
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "FileConnection",
            "kind": "LinkedField",
            "name": "files",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "files(first:100)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InlineExperimentViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Experiment",
        "kind": "LinkedField",
        "name": "experiment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Parameters",
            "kind": "LinkedField",
            "name": "parameters",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Metrics",
            "kind": "LinkedField",
            "name": "metrics",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "DirectoryConnection",
            "kind": "LinkedField",
            "name": "directories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DirectoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Directory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "directories(first:100)"
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "FileConnection",
            "kind": "LinkedField",
            "name": "files",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "files(first:100)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ef45891486c760e26fce5098afc092b",
    "id": null,
    "metadata": {},
    "name": "InlineExperimentViewQuery",
    "operationKind": "query",
    "text": "query InlineExperimentViewQuery(\n  $id: ID!\n) {\n  experiment(id: $id) {\n    id\n    name\n    path\n    parameters {\n      name\n      path\n      flat\n      id\n    }\n    metrics {\n      name\n      path\n      keys\n      id\n    }\n    directories(first: 100) {\n      edges {\n        node {\n          name\n          path\n          id\n        }\n      }\n    }\n    files(first: 100) {\n      edges {\n        node {\n          name\n          path\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7e304746e687f0e1f16c3284d9000418';

module.exports = node;
