import React, {useEffect, useMemo, useRef, useState} from "react";
import {QueryRenderer} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {by, strOrder} from "../../lib/string-sort";
import {modernEnvironment} from "../../data";
import {toGlobalId} from "../../lib/relay-helpers";
import {FixedSizeList as List} from 'react-window';
import styled from "styled-components";


/* A component for queue list view.
 * It uses react-window grid just as Ge did in ExperimentList (/src/pages/Dash/ExperimentList.js).
 *
 */
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  background: white;
  overflow: hidden;
  text-wrap: none;
  border-bottom: 1px solid #eee;
`;
const StyledCell = styled.div`
  line-height: 100%;
  padding: 7px 0px 7px 10px;
`;
const StatusCell = styled.div`
  line-height: 100%;
  padding: 7px 0px 7px 10px;
  ${({status}) => {
    switch (status) {
      case "started":
        return "color: white; background: yellow;\n"
        break
      case "requested":
        return "color: white; background: orange;\n"
        break
      case "running":
        return "color: white; background: #23aaff;\n"
        break
      case "completed":
        return "color: white; background: green;\n"
        break
      case "errored":
        return "color: white; background: red;\n"
        break
    }
  }}
`;
// const FixedCell = StyledCell`
//   width: ${props => props.width}px;
// `;
const RowRenderer = ({index, data, ..._props}) => {
    /* This is a RowRenderer function specifically for react-window List. */
    const {name, path, parameters} = data[index];
    const {flat} = parameters;

    return <StyledRow odd={index % 2} {..._props}>
        <StyledCell>{path}</StyledCell>
        <StatusCell status={flat["job.status"]}>{flat["job.status"]}</StatusCell>
    </StyledRow>
}

const JobList = ({
                     path,
                     selected, onSelect,
                     breadCrumbs, directory, loadMore, ..._props
                 }) => {
    const {name, directories, experiments} = directory || {};
    const [expList, setExpList] = useState([]);
    const [textQuery, setTextQuery] = useState("");

    useEffect(() => {
        setExpList(
            (experiments && experiments.edges || [])
                .filter(_ => _ !== null)
                .map(({node}) => node)
                .filter(({path}) => !textQuery || (path && path.match(textQuery)))
                .filter(_ => _ !== null)
                .sort(by(strOrder, "path"))
                .reverse());
    }, [textQuery, experiments]);

    return (
        <>
            <input type="text" onChange={({target}) => setTextQuery(target.value)} value={textQuery}
                   style={{width: "100%"}}/>
            <List height={900} itemCount={expList.length} itemSize={35} itemData={expList}
            >{RowRenderer}</List>
        </>
    )
}


export default function ({path, ..._props}) {
    let dirID = toGlobalId("Directory", path);
    //todo: move all of these to the reFetch container inside the directory list view.
    //  so that we don't refresh the component on "loadMore"
    const [limit, setLimit] = useState(50);

    function loadMore() {
        setLimit(limit + 50)
    }

    return <QueryRenderer
        environment={modernEnvironment}
        cacheConfig={{force: true}}
        variables={{dirID, first: limit}}
        query={
            graphql`
        query JobListQuery ($dirID:ID!, $first:Int) {
            directory: node(id: $dirID){
                ...on Directory {
                    name
                    experiments (first:$first) {
                        pageInfo { endCursor hasNextPage }
                        edges {
                            cursor
                            node {
                                id
                                path
                                parameters { flat }
                                # metrics { value }
                            }
                        }

                    }
                }
            }}
        `}
        render={
            ({error, props, retry}) => <JobList path={path} loadMore={loadMore} {...props} {..._props}/>
        }/>;
}
