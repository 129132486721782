/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MutateTextFileInput = {|
  id?: ?string,
  text: string,
  clientMutationId?: ?string,
|};
export type ExperimentDashUpdateMutationVariables = {|
  input: MutateTextFileInput
|};
export type ExperimentDashUpdateMutationResponse = {|
  +updateText: ?{|
    +file: ?{|
      +id: string,
      +name: ?string,
      +path: ?string,
      +text: ?string,
      +yaml: ?any,
    |}
  |}
|};
export type ExperimentDashUpdateMutation = {|
  variables: ExperimentDashUpdateMutationVariables,
  response: ExperimentDashUpdateMutationResponse,
|};
*/


/*
mutation ExperimentDashUpdateMutation(
  $input: MutateTextFileInput!
) {
  updateText(input: $input) {
    file {
      id
      name
      path
      text
      yaml
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MutateTextFilePayload",
    "kind": "LinkedField",
    "name": "updateText",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "file",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "yaml",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExperimentDashUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExperimentDashUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "de4a693cb2dda97f026e27037c5257f3",
    "id": null,
    "metadata": {},
    "name": "ExperimentDashUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ExperimentDashUpdateMutation(\n  $input: MutateTextFileInput!\n) {\n  updateText(input: $input) {\n    file {\n      id\n      name\n      path\n      text\n      yaml\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8ceb472f15072f06da37f646e7d8d67';

module.exports = node;
