/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ParamsTableQueryVariables = {|
  metricsFiles: $ReadOnlyArray<?string>,
  prefix?: ?string,
  yKey?: ?string,
  tail?: ?number,
|};
export type ParamsTableQueryResponse = {|
  +series: ?{|
    +id: string,
    +yKey: ?string,
    +yMean: ?any,
    +y25pc: ?any,
    +y75pc: ?any,
  |}
|};
export type ParamsTableQuery = {|
  variables: ParamsTableQueryVariables,
  response: ParamsTableQueryResponse,
|};
*/


/*
query ParamsTableQuery(
  $metricsFiles: [String]!
  $prefix: String
  $yKey: String
  $tail: Int
) {
  series(metricsFiles: $metricsFiles, prefix: $prefix, yKey: $yKey, k: 1, tail: $tail) {
    id
    yKey
    yMean
    y25pc
    y75pc
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metricsFiles"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prefix"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tail"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yKey"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "k",
        "value": 1
      },
      {
        "kind": "Variable",
        "name": "metricsFiles",
        "variableName": "metricsFiles"
      },
      {
        "kind": "Variable",
        "name": "prefix",
        "variableName": "prefix"
      },
      {
        "kind": "Variable",
        "name": "tail",
        "variableName": "tail"
      },
      {
        "kind": "Variable",
        "name": "yKey",
        "variableName": "yKey"
      }
    ],
    "concreteType": "Series",
    "kind": "LinkedField",
    "name": "series",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "yKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "yMean",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "y25pc",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "y75pc",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ParamsTableQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ParamsTableQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "cba23d68536c9b13521f5b66cfe06770",
    "id": null,
    "metadata": {},
    "name": "ParamsTableQuery",
    "operationKind": "query",
    "text": "query ParamsTableQuery(\n  $metricsFiles: [String]!\n  $prefix: String\n  $yKey: String\n  $tail: Int\n) {\n  series(metricsFiles: $metricsFiles, prefix: $prefix, yKey: $yKey, k: 1, tail: $tail) {\n    id\n    yKey\n    yMean\n    y25pc\n    y75pc\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6a81fe2ba4394f917fbdb5e22b41914';

module.exports = node;
